import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'; // Changed icon
import logo from './img/logo.png';

const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

const MarqueeAnimation = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const TextTwinkle = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 300% 300%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  color: #E0E0E0;
  position: relative; /* Add this to position the help icon */
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-top: 20px;
`;

const WelcomeMessage = styled.h1`
  font-size: 1em;
  margin: 50px 0;
  color: #ffffff;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  animation: ${MarqueeAnimation} 11s linear infinite;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;

const ClickableButton = styled.div`
  font-size: 1.2em;
  color: #E0E0E0;
  cursor: pointer;
  padding: 15px;
  border: 2px solid #E0E0E0;
  border-radius: 5px;
  transition: color 0.3s, transform 0.3s;
  animation: ${TextTwinkle} 2s ease-in-out infinite;
  
  &:hover {
    color: #ffd700;
    transform: scale(1.05);
  }
`;

const HelpContainer = styled.div`
  position: absolute;
  bottom: 55px;  /* Adjust as needed */
  right: 20px;   /* Adjust as needed */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the icon and text */
  cursor: pointer;
  color: #E0E0E0;  /* Icon color */
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1); /* Scale up on hover */
    color: #ffd700; /* Change color on hover */
  }
`;

const HelpText = styled.span`
  font-size: 0.8em; /* Adjust font size for the help text */
  margin-top: 5px; /* Space between icon and text */
`;

const UserDashboard = () => {
  const [fullName, setFullName] = useState('User');
  const navigate = useNavigate();

  useEffect(() => {
    const storedFullName = sessionStorage.getItem('fullName') || 'User';
    setFullName(storedFullName);
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleHelpClick = () => {
    navigate('/needhelppage'); // Change to your actual path
  };

  return (
    <DashboardContainer>
      <Logo src={logo} alt="Company Logo" />
      <WelcomeMessage>
        Welcome {fullName} 😄, We hope your day is going great 😊. Choose an option below to proceed.
      </WelcomeMessage>

      <ButtonContainer>
        <ClickableButton onClick={() => handleNavigation('/discover-us-online')}>
          Discover Us Online
        </ClickableButton>
        <ClickableButton onClick={() => handleNavigation('/about-trackpi')}>
          About Trackpi
        </ClickableButton>
        <ClickableButton onClick={() => handleNavigation('/contact-us')}>
          Connect Us
        </ClickableButton>
        <ClickableButton onClick={() => handleNavigation('/my-course')}>
          My Course
        </ClickableButton>
        <ClickableButton onClick={() => handleNavigation('/faq')}>
          FAQ
        </ClickableButton>
      </ButtonContainer>

      <HelpContainer onClick={handleHelpClick}>
        <FontAwesomeIcon icon={faQuestionCircle} size="2x" /> {/* Adjust icon size as needed */}
        <HelpText>Need Help?</HelpText>
      </HelpContainer>
    </DashboardContainer>
  );
};

export default UserDashboard;
