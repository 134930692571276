import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Vimeo from '@vimeo/player'; // Make sure to install the Vimeo player package
import logo from './img/logo.png'; // Import the company logo
import styled, { keyframes } from 'styled-components';

// Keyframes for the background animation
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

// Loading Animation (Spinner)
const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled-components for mobile-friendly and responsive design
const VideoPlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; // Centers content vertically
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 300% 300%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  color: #E0E0E0;
  position: relative; // Ensure relative positioning for the loading screen
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const VideoTitle = styled.h1`
  font-size: 1.5em;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const PlayerWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 20px auto; // Space between the title and player
  iframe {
    width: 100%;
    height: 360px;
    @media (max-width: 768px) {
      height: 200px; // Adjust height for mobile
    }
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

const CompleteButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffd700;
  border: none;
  color: #141414;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.9em;
  }
`;

// Loading Screen
const LoadingScreen = styled.div`
  position: absolute; // Absolute positioning
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(10, 10, 10, 10); // Ash background
  color: #ffd700;
  font-size: 13px;
`;

const Spinner = styled.div`
  margin-top: 20px;
  width: 20px;
  height: 20px;
  border: 5px solid #ffd700;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

const VideoPlayer = () => {
    const { state } = useLocation(); // Get the passed state from navigation
    const { videoId, videoTitle, assessmentId, assessmentName, courseName, username } = state || {}; // Add courseName and username
    const playerRef = useRef(null);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(''); // For error messages
    const [isLoading, setIsLoading] = useState(false); // For loading screen

    useEffect(() => {
        if (!videoId) {
            alert("Video not found!");
            navigate('/my-courses'); // Redirect to my courses page
            return;
        }

        // Create a new Vimeo player instance
        playerRef.current = new Vimeo('vimeo-player', {
            id: videoId,
            width: 640,
        });

        // Cleanup player on unmount
        return () => {
            if (playerRef.current) { // Add this check to prevent null errors
                playerRef.current.destroy();
            }
        };
    }, [videoId, navigate]);

    // Function to handle Mark Unit Complete button click
    const handleMarkUnitComplete = async () => {
        try {
            setIsLoading(true); // Show loading screen
            // Add a small delay to allow the loading screen to be visible
            setTimeout(() => {
                navigate(`/assessment`, { 
                    state: { 
                        assessmentId, 
                        assessmentName, 
                        courseName, // Pass courseName to assessment page
                        username // Pass username to assessment page
                    } 
                });
            }, 1000); // 1-second delay before navigation
        } catch (error) {
            console.error("Error navigating to assessment:", error);
            setErrorMessage('Failed to navigate to the assessment page. Please try again later.');
            setIsLoading(false); // Hide loading screen in case of error
        }
    };

    return (
        <VideoPlayerContainer>
            <Logo src={logo} alt="Company Logo" />
            <VideoTitle>{videoTitle}</VideoTitle> {/* Video title */}
            <PlayerWrapper>
                <div id="vimeo-player" ref={playerRef} /> {/* Vimeo player */}
            </PlayerWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <CompleteButton onClick={handleMarkUnitComplete}>
                Mark Unit Complete
            </CompleteButton>

            {/* Loading Screen */}
            {isLoading && (
                <LoadingScreen>
                    Getting things ready, stay focused and good luck!
                    <Spinner />
                </LoadingScreen>
            )}
        </VideoPlayerContainer>
    );
};

export default VideoPlayer;
