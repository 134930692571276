import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHardHat } from '@fortawesome/free-solid-svg-icons'; // Construction icon

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  color: #E0E0E0;
`;

const UnderConstructionMessage = styled.h2`
  margin-top: 20px; /* Space above the message */
  color: #ffd700; /* Gold color for visibility */
  text-align: center; /* Center align the text */
  display: flex;
  align-items: center; /* Align icon and text vertically */
  gap: 10px; /* Space between icon and text */
`;

const FAQ = () => {
  return (
    <PageContainer>
      <h1>Frequently Asked Questions</h1>
      <UnderConstructionMessage>
        <FontAwesomeIcon icon={faHardHat} size="2x" /> {/* Construction icon */}
        This page is currently under construction. Please check back later!
      </UnderConstructionMessage>
    </PageContainer>
  );
};

export default FAQ;
