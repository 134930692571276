import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from './img/logo.png';

// Dynamically import all images from './img'
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '').replace('.png', '')] = r(item);
  });
  return images;
};

const images = importAll(require.context('./img', false, /\.(png|jpe?g|svg)$/));

// Background and Loading animations
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled components
const MyCoursesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 300% 300%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  color: white;
  padding: 20px;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-top: 20px;
`;

const CourseCard = styled.div`
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ffd700;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e2a;
`;

const CourseLogo = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background: #ffd700;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  color: #ffd700;
  font-size: 13px;
`;

const Spinner = styled.div`
  margin-top: 20px;
  width: 20px;
  height: 20px;
  border: 5px solid #ffd700;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

const MyCourses = () => {
  const [courses, setCourses] = useState([]);
  const [progress, setProgress] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true); // General loading state
  const [customLoadingMessage, setCustomLoadingMessage] = useState(''); // Custom loading message
  const navigate = useNavigate();

  useEffect(() => {
    const username = sessionStorage.getItem('username');

    const fetchCoursesAndProgress = async () => {
      try {
        // Fetch courses
        const coursesResponse = await axios.post('https://courseapp1.onrender.com/api/getCourses', { username });
        const fetchedCourses = coursesResponse.data.courses || [];
        setCourses(fetchedCourses);

        // Fetch progress data
        const progressResponse = await axios.post('https://courseapp1.onrender.com/api/getProgress', { username });
        const progressData = progressResponse.data;

        // Map progress data
        const progressMapping = {};
        progressData.forEach(item => {
          progressMapping[item.courseName] = item.videoIndex; // Use videoIndex from Column C
        });
        setProgress(progressMapping);
      } catch (error) {
        console.error('Error fetching data:', error);
        setErrorMessage('Failed to fetch courses or progress data. Please try again later.');
      } finally {
        setLoading(false); // Hide loading once data is fetched
      }
    };

    fetchCoursesAndProgress();
  }, []);

  const handleStartCourse = async (course) => {
    const videoIndex = progress[course];

    if (videoIndex === 'Completed') {
      // Show a popup if the user tries to restart a completed course
      alert('If you are trying to restart the course again, please reach out to your reporting manager. Thank you.');
      return;
    }

    try {
      const username = sessionStorage.getItem('username');
      const videoIndexNumber = parseInt(videoIndex, 10) || 0; // Ensure it's a number

      // Set the loading message based on whether the course is new or continued
      if (videoIndexNumber === 0) {
        setCustomLoadingMessage('Get ready! You are about to begin your journey.');
      } else {
        setCustomLoadingMessage('Let’s start from where you left off!');
      }

      setLoading(true); // Display loading screen

      // Fetch all video details for the selected course
      const response = await axios.post('https://courseapp1.onrender.com/api/getVideoDetails', { courseName: course });
      const videoDetails = response.data;

      const currentVideo = videoDetails[videoIndexNumber]; // Get the video object based on index

      if (!currentVideo) {
        throw new Error('No video available for the current index.');
      }

      const { videoId, videoTitle, assessmentId, assessmentName } = currentVideo;

      // Navigate to the video player page with video details and username
      navigate(`/video-player/${videoId}`, {
        state: {
          username,
          courseName: course,
          videoId,
          videoTitle,
          assessmentId,
          assessmentName,
          startIndex: videoIndexNumber
        }
      });
    } catch (error) {
      console.error('Error starting course:', error);
      setErrorMessage('Failed to start the course. Please try again later.');
      setLoading(false); // Hide loading screen on error
    }
  };

  if (loading) {
    return (
      <LoadingScreen>
        {customLoadingMessage || 'Fetching your courses...'}
        <Spinner />
      </LoadingScreen>
    );
  }

  return (
    <MyCoursesWrapper>
      <Logo src={logo} alt="Company Logo" />
      <h1>My Courses</h1>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {courses.map(course => {
        const videoIndex = progress[course]; // Get video index or status
        let buttonText;

        if (videoIndex === 'Completed') {
          buttonText = 'Course Completed';
        } else if (parseInt(videoIndex, 10) === 0 || isNaN(parseInt(videoIndex, 10))) {
          buttonText = 'Start Course';
        } else {
          buttonText = 'Continue Course';
        }

        const courseLogo = images[course] || images['default'];

        return (
          <CourseCard key={course}>
            <CourseLogo src={courseLogo} alt={course} />
            <h2>{course}</h2>
            <Button onClick={() => handleStartCourse(course)}>
              {buttonText}
            </Button>
          </CourseCard>
        );
      })}
    </MyCoursesWrapper>
  );
};

export default MyCourses;
