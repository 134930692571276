import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  color: #E0E0E0;
`;

const Heading = styled.h1`
  margin-bottom: 40px; /* Space below heading */
`;

const ContactInfo = styled.div`
  text-align: center;
  font-size: 18px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-align icons and text */
  gap: 30px; /* Space between each contact item */
`;

const IconText = styled.div`
  display: flex;
  align-items: center; /* Align icon and text vertically */
  color: #E0E0E0; /* White color for text */
  
  & svg {
    color: #ffd700; /* Yellow color for icons */
    margin-right: 15px; /* Space between icon and text */
    font-size: 24px; /* Ensure icons are a uniform size */
  }

  font-size: 16px; /* Adjust font size as needed */
`;

const ContactUs = () => {
  return (
    <PageContainer>
      <Heading>Connect With Us</Heading>
      <ContactInfo>
        <IconContainer>
          <IconText>
            <FontAwesomeIcon icon={faEnvelope} /> hr@trackpi.in
          </IconText>
          <IconText>
            <FontAwesomeIcon icon={faPhone} /> (+91) 80781 79646
          </IconText>
          <IconText>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Trackpi Private Limited, KC Arcade, Near Tv Centre, Cochin Special Economic Zone, Ernakulam, Kerala - 682037.
          </IconText>
        </IconContainer>
      </ContactInfo>
    </PageContainer>
  );
};

export default ContactUs;
