import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from './img/logo.png'; // Import company logo
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation

// Keyframes for background animation
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

// Wrapper for Family Form Page
const FamilyFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  font-family: 'Poppins', sans-serif;
  color: #E0E0E0;
  padding: 20px;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const Title = styled.h1`
  color: #ffd700; // Gold color for title
  margin-bottom: 20px;
  text-align: center; // Center align the title
`;

const InputField = styled.input`
  width: 100%;
  max-width: 400px; // Maximum width for input fields
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ffd700; // Gold border
  border-radius: 5px;
  font-size: 1em;
  color: #141414; // Dark text color
`;

const SelectField = styled.select`
  width: 100%;
  max-width: 400px; // Maximum width for select fields
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ffd700; // Gold border
  border-radius: 5px;
  font-size: 1em;
  color: #141414; // Dark text color
`;

const SignUpButton = styled.button`
  padding: 10px 20px;
  background-color: #ffd700; // Button color
  border: none;
  color: #141414; // Button text color
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  margin: 10px 0; // Space between buttons
`;

// Modal for Confirmation Message
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); // Dark overlay for better visibility
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it appears on top of other content
`;

const ModalContent = styled.div`
  background: #1e1e2a; // Match the background color
  color: #ffd700; // Gold text color
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px; // Set a fixed width for modal
`;

const ModalButton = styled.button`
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #ffd700; // Button color
  border: none;
  color: #141414; // Button text color
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0b800; // Darken the button on hover
  }
`;

// Main Family Form Component
const FamilyFormPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    countryCode: '',
    phoneNumber: '',
    email: '',
    gender: '',
    bankName: '',
    bankAccountNumber: '',
    ifscCode: '',
    branch: '',
    modeOfSalaryIntake: '',
    bloodGroup: '',
    referralCode: '' // New field added
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const countryCodes = [
    { code: '+93', name: 'Afghanistan' },
    { code: '+355', name: 'Albania' },
    { code: '+213', name: 'Algeria' },
    { code: '+1', name: 'United States' },
    { code: '+376', name: 'Andorra' },
    { code: '+244', name: 'Angola' },
    { code: '+1', name: 'Anguilla' },
    { code: '+672', name: 'Antarctica' },
    { code: '+54', name: 'Argentina' },
    { code: '+374', name: 'Armenia' },
    { code: '+297', name: 'Aruba' },
    { code: '+61', name: 'Australia' },
    { code: '+43', name: 'Austria' },
    { code: '+994', name: 'Azerbaijan' },
    { code: '+1', name: 'Bahamas' },
    { code: '+973', name: 'Bahrain' },
    { code: '+880', name: 'Bangladesh' },
    { code: '+1', name: 'Barbados' },
    { code: '+375', name: 'Belarus' },
    { code: '+32', name: 'Belgium' },
    { code: '+501', name: 'Belize' },
    { code: '+229', name: 'Benin' },
    { code: '+1', name: 'Bermuda' },
    { code: '+975', name: 'Bhutan' },
    { code: '+591', name: 'Bolivia' },
    { code: '+387', name: 'Bosnia and Herzegovina' },
    { code: '+267', name: 'Botswana' },
    { code: '+55', name: 'Brazil' },
    { code: '+673', name: 'Brunei' },
    { code: '+359', name: 'Bulgaria' },
    { code: '+226', name: 'Burkina Faso' },
    { code: '+257', name: 'Burundi' },
    { code: '+855', name: 'Cambodia' },
    { code: '+237', name: 'Cameroon' },
    { code: '+1', name: 'Canada' },
    { code: '+238', name: 'Cape Verde' },
    { code: '+345', name: 'Cayman Islands' },
    { code: '+236', name: 'Central African Republic' },
    { code: '+61', name: 'Chad' },
    { code: '+56', name: 'Chile' },
    { code: '+86', name: 'China' },
    { code: '+57', name: 'Colombia' },
    { code: '+269', name: 'Comoros' },
    { code: '+682', name: 'Cook Islands' },
    { code: '+506', name: 'Costa Rica' },
    { code: '+225', name: 'Côte d\'Ivoire' },
    { code: '+385', name: 'Croatia' },
    { code: '+53', name: 'Cuba' },
    { code: '+599', name: 'Curaçao' },
    { code: '+357', name: 'Cyprus' },
    { code: '+420', name: 'Czech Republic' },
    { code: '+243', name: 'Democratic Republic of the Congo' },
    { code: '+236', name: 'Republic of the Congo' },
    { code: '+45', name: 'Denmark' },
    { code: '+253', name: 'Djibouti' },
    { code: '+1', name: 'Dominica' },
    { code: '+1809', name: 'Dominican Republic' },
    { code: '+670', name: 'East Timor' },
    { code: '+593', name: 'Ecuador' },
    { code: '+20', name: 'Egypt' },
    { code: '+503', name: 'El Salvador' },
    { code: '+240', name: 'Equatorial Guinea' },
    { code: '+291', name: 'Eritrea' },
    { code: '+372', name: 'Estonia' },
    { code: '+268', name: 'Eswatini' },
    { code: '+251', name: 'Ethiopia' },
    { code: '+500', name: 'Falkland Islands' },
    { code: '+298', name: 'Faroe Islands' },
    { code: '+33', name: 'France' },
    { code: '+594', name: 'French Guiana' },
    { code: '+689', name: 'French Polynesia' },
    { code: '+241', name: 'Gabon' },
    { code: '+220', name: 'Gambia' },
    { code: '+995', name: 'Georgia' },
    { code: '+49', name: 'Germany' },
    { code: '+233', name: 'Ghana' },
    { code: '+350', name: 'Gibraltar' },
    { code: '+30', name: 'Greece' },
    { code: '+299', name: 'Greenland' },
    { code: '+1', name: 'Grenada' },
    { code: '+590', name: 'Guadeloupe' },
    { code: '+1', name: 'Guam' },
    { code: '+502', name: 'Guatemala' },
    { code: '+224', name: 'Guinea' },
    { code: '+245', name: 'Guinea-Bissau' },
    { code: '+595', name: 'Paraguay' },
    { code: '+509', name: 'Haiti' },
    { code: '+504', name: 'Honduras' },
    { code: '+36', name: 'Hungary' },
    { code: '+354', name: 'Iceland' },
    { code: '+91', name: 'India' },
    { code: '+62', name: 'Indonesia' },
    { code: '+98', name: 'Iran' },
    { code: '+964', name: 'Iraq' },
    { code: '+353', name: 'Ireland' },
    { code: '+972', name: 'Israel' },
    { code: '+39', name: 'Italy' },
    { code: '+1', name: 'Jamaica' },
    { code: '+81', name: 'Japan' },
    { code: '+44', name: 'Jersey' },
    { code: '+962', name: 'Jordan' },
    { code: '+7', name: 'Kazakhstan' },
    { code: '+254', name: 'Kenya' },
    { code: '+686', name: 'Kiribati' },
    { code: '+965', name: 'Kuwait' },
    { code: '+996', name: 'Kyrgyzstan' },
    { code: '+855', name: 'Laos' },
    { code: '+371', name: 'Latvia' },
    { code: '+961', name: 'Lebanon' },
    { code: '+266', name: 'Lesotho' },
    { code: '+231', name: 'Liberia' },
    { code: '+218', name: 'Libya' },
    { code: '+423', name: 'Liechtenstein' },
    { code: '+370', name: 'Lithuania' },
    { code: '+352', name: 'Luxembourg' },
    { code: '+853', name: 'Macau' },
    { code: '+389', name: 'Macedonia' },
    { code: '+261', name: 'Madagascar' },
    { code: '+265', name: 'Malawi' },
    { code: '+60', name: 'Malaysia' },
    { code: '+960', name: 'Maldives' },
    { code: '+223', name: 'Mali' },
    { code: '+356', name: 'Malta' },
    { code: '+692', name: 'Marshall Islands' },
    { code: '+596', name: 'Martinique' },
    { code: '+222', name: 'Mauritania' },
    { code: '+230', name: 'Mauritius' },
    { code: '+262', name: 'Mayotte' },
    { code: '+52', name: 'Mexico' },
    { code: '+691', name: 'Micronesia' },
    { code: '+373', name: 'Moldova' },
    { code: '+377', name: 'Monaco' },
    { code: '+976', name: 'Mongolia' },
    { code: '+382', name: 'Montenegro' },
    { code: '+1664', name: 'Montserrat' },
    { code: '+212', name: 'Morocco' },
    { code: '+258', name: 'Mozambique' },
    { code: '+95', name: 'Myanmar' },
    { code: '+264', name: 'Namibia' },
    { code: '+674', name: 'Nauru' },
    { code: '+977', name: 'Nepal' },
    { code: '+31', name: 'Netherlands' },
    { code: '+599', name: 'Netherlands Antilles' },
    { code: '+64', name: 'New Zealand' },
    { code: '+505', name: 'Nicaragua' },
    { code: '+227', name: 'Niger' },
    { code: '+234', name: 'Nigeria' },
    { code: '+683', name: 'Niue' },
    { code: '+64', name: 'Norfolk Island' },
    { code: '+1', name: 'Northern Mariana Islands' },
    { code: '+47', name: 'Norway' },
    { code: '+968', name: 'Oman' },
    { code: '+92', name: 'Pakistan' },
    { code: '+680', name: 'Palau' },
    { code: '+970', name: 'Palestine' },
    { code: '+507', name: 'Panama' },
    { code: '+675', name: 'Papua New Guinea' },
    { code: '+595', name: 'Paraguay' },
    { code: '+51', name: 'Peru' },
    { code: '+63', name: 'Philippines' },
    { code: '+48', name: 'Poland' },
    { code: '+351', name: 'Portugal' },
    { code: '+1', name: 'Puerto Rico' },
    { code: '+974', name: 'Qatar' },
    { code: '+262', name: 'Réunion' },
    { code: '+40', name: 'Romania' },
    { code: '+7', name: 'Russia' },
    { code: '+250', name: 'Rwanda' },
    { code: '+378', name: 'San Marino' },
    { code: '+239', name: 'São Tomé and Príncipe' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+221', name: 'Senegal' },
    { code: '+381', name: 'Serbia' },
    { code: '+248', name: 'Seychelles' },
    { code: '+232', name: 'Sierra Leone' },
    { code: '+65', name: 'Singapore' },
    { code: '+421', name: 'Slovakia' },
    { code: '+386', name: 'Slovenia' },
    { code: '+677', name: 'Solomon Islands' },
    { code: '+252', name: 'Somalia' },
    { code: '+27', name: 'South Africa' },
    { code: '+82', name: 'South Korea' },
    { code: '+34', name: 'Spain' },
    { code: '+94', name: 'Sri Lanka' },
    { code: '+249', name: 'Sudan' },
    { code: '+597', name: 'Suriname' },
    { code: '+268', name: 'Swaziland' },
    { code: '+46', name: 'Sweden' },
    { code: '+41', name: 'Switzerland' },
    { code: '+963', name: 'Syria' },
    { code: '+886', name: 'Taiwan' },
    { code: '+992', name: 'Tajikistan' },
    { code: '+255', name: 'Tanzania' },
    { code: '+66', name: 'Thailand' },
    { code: '+670', name: 'Timor-Leste' },
    { code: '+228', name: 'Togo' },
    { code: '+690', name: 'Tokelau' },
    { code: '+676', name: 'Tonga' },
    { code: '+1', name: 'Trinidad and Tobago' },
    { code: '+216', name: 'Tunisia' },
    { code: '+90', name: 'Turkey' },
    { code: '+993', name: 'Turkmenistan' },
    { code: '+1', name: 'Turks and Caicos Islands' },
    { code: '+688', name: 'Tuvalu' },
    { code: '+256', name: 'Uganda' },
    { code: '+380', name: 'Ukraine' },
    { code: '+971', name: 'United Arab Emirates' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+1', name: 'United States' },
    { code: '+598', name: 'Uruguay' },
    { code: '+998', name: 'Uzbekistan' },
    { code: '+678', name: 'Vanuatu' },
    { code: '+379', name: 'Vatican City' },
    { code: '+58', name: 'Venezuela' },
    { code: '+84', name: 'Vietnam' },
    { code: '+681', name: 'Wallis and Futuna' },
    { code: '+967', name: 'Yemen' },
    { code: '+260', name: 'Zambia' },
    { code: '+263', name: 'Zimbabwe' },
    // Note: You may want to include territories or special regions based on your requirements.
  ];    
  const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-']; // Available blood groups
  const salaryModes = ['Weekly Payment', 'Monthly Payment']; // Salary intake modes
  const genders = ['Male', 'Female'];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateInput = () => {
    let errors = {};
    if (!formData.firstName || formData.firstName.length < 2) {
      errors.firstName = 'First Name should be at least 2 characters';
    }
    if (!formData.lastName || formData.lastName.length < 2) {
      errors.lastName = 'Last Name should be at least 2 characters';
    }
    if (!formData.phoneNumber || formData.phoneNumber.length < 10 || formData.phoneNumber.length > 12) {
      errors.phoneNumber = 'Phone number should be between 10 to 12 digits';
    }
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    if (!formData.bankAccountNumber || formData.bankAccountNumber.length < 10) {
      errors.bankAccountNumber = 'Bank account number should be at least 10 digits';
    }
    if (!formData.ifscCode || formData.ifscCode.length !== 11) {
      errors.ifscCode = 'IFSC code should be 11 characters';
    }
    return errors;
  };

  const saveFamilyDetails = async (familyData) => {
    try {
      const response = await fetch('https://courseapp1.onrender.com/api/saveFamilyDetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(familyData),
      });

      // Handle the response
      if (response.ok) {
        // Show confirmation modal
        setModalVisible(true);

        // Optionally reset the form
        setFormData({
          firstName: '',
          lastName: '',
          dob: '',
          countryCode: '',
          phoneNumber: '',
          email: '',
          gender: '',
          bankName: '',
          bankAccountNumber: '',
          ifscCode: '',
          branch: '',
          modeOfSalaryIntake: '',
          bloodGroup: '',
          referralCode: ''
        });
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.error); // Display error message
      }
    } catch (error) {
      console.error('Error:', error); // Handle network errors
    }
  };

  // On form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
    const errors = validateInput();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      saveFamilyDetails(formData); // Send form data if no errors
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    navigate('/'); // Navigate to index page after closing the modal
  };

  return (
    <FamilyFormWrapper>
      <Logo src={logo} alt="Company Logo" />
      <Title>Enter Your Details for Onboarding</Title>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <InputField
          type="text"
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          required
        />
        {formErrors.firstName && <p style={{ color: 'red' }}>{formErrors.firstName}</p>}
        
        <InputField
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          required
        />
        {formErrors.lastName && <p style={{ color: 'red' }}>{formErrors.lastName}</p>}
        
        <InputField
        type="text" // Use 'text' for placeholder functionality
        placeholder="Date of Birth (dd-mm-yyyy)"
        name="dob"
        value={formData.dob}
        onChange={handleInputChange}
        required
        />
        
        <SelectField
          name="countryCode"
          value={formData.countryCode}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Country Code</option>
          {countryCodes.map(({ code, name }) => (
            <option key={code} value={code}>
          {name} ({code})
          </option>
          ))}
        </SelectField>
        
        <InputField
          type="tel"
          placeholder="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          required
        />
        {formErrors.phoneNumber && <p style={{ color: 'red' }}>{formErrors.phoneNumber}</p>}
        
        <InputField
          type="email"
          placeholder="Email Address"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        {formErrors.email && <p style={{ color: 'red' }}>{formErrors.email}</p>}
        
        <SelectField
          name="gender"
          value={formData.gender}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Gender</option>
          {genders.map((gender) => (
            <option key={gender} value={gender}>
              {gender}
            </option>
          ))}
        </SelectField>
        
        <InputField
          type="text"
          placeholder="Bank Name"
          name="bankName"
          value={formData.bankName}
          onChange={handleInputChange}
          required
        />
        
        <InputField
          type="number"
          placeholder="Bank Account Number"
          name="bankAccountNumber"
          value={formData.bankAccountNumber}
          onChange={handleInputChange}
          required
        />
        {formErrors.bankAccountNumber && <p style={{ color: 'red' }}>{formErrors.bankAccountNumber}</p>}
        
        <InputField
          type="text"
          placeholder="IFSC Code"
          name="ifscCode"
          value={formData.ifscCode}
          onChange={handleInputChange}
          required
        />
        {formErrors.ifscCode && <p style={{ color: 'red' }}>{formErrors.ifscCode}</p>}
        
        <InputField
          type="text"
          placeholder="Branch"
          name="branch"
          value={formData.branch}
          onChange={handleInputChange}
          required
        />
        
        <SelectField
          name="modeOfSalaryIntake"
          value={formData.modeOfSalaryIntake}
          onChange={handleInputChange}
          required
        >
          <option value="">Mode of Salary Intake</option>
          {salaryModes.map((mode) => (
            <option key={mode} value={mode}>
              {mode}
            </option>
          ))}
        </SelectField>
        
        <SelectField
          name="bloodGroup"
          value={formData.bloodGroup}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Blood Group</option>
          {bloodGroups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </SelectField>
        
        <InputField
          type="text"
          placeholder="Referral Code (Optional)"
          name="referralCode"
          value={formData.referralCode}
          onChange={handleInputChange}
        />
        
        <SignUpButton type="submit">Submit Details</SignUpButton>
      </form>

      {modalVisible && (
        <ModalOverlay>
          <ModalContent>
            <h2>Submission Successful!</h2>
            <p>Congratulations and welcome to the Trackpi team! 🎉 Your reporting manager will be in touch within the next 24 hours to get you started. We’re thrilled to have you onboard!</p>
            <ModalButton onClick={handleModalClose}>Thank you</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </FamilyFormWrapper>
  );
};

export default FamilyFormPage;
