import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';

// Styled Components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  color: #E0E0E0;
`;

const Heading = styled.h1`
  font-size: 1em; /* Adjust the font size */
  color: #E0E0E0; /* Adjust the color if needed */
  text-align: center; /* Center align text */
  margin-bottom: 20px; /* Adjust spacing below the heading */
  position: relative;
  top: -40px; /* Adjust vertical positioning */
`;

const IconContainer = styled.div`
  display: flex;
  gap: 40px; /* Adjust the space between icons */
  font-size: 40px; /* Adjust the font size */
  color: #ffd700;
  margin-top: 20px;
`;

const IconLink = styled.a`
  color: #ffd700; /* Match the icon color */
  transition: transform 0.3s; /* Smooth transition effect */

  &:hover {
    transform: scale(1.1); /* Scale up on hover */
  }
`;

const DiscoverUsOnline = () => {
  return (
    <PageContainer>
      <Heading>Get the latest updates from Trackpi!</Heading>
      <IconContainer>
        <IconLink href="https://www.facebook.com/profile.php?id=61565947096778" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} />
        </IconLink>
        <IconLink href="https://www.instagram.com/trackpi_official?igsh=YmwyaHpzYXBueWJz" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </IconLink>
        <IconLink href="https://www.youtube.com/@Trackpi-p3s" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </IconLink>
        <IconLink href="https://www.linkedin.com/company/trackpi-private-limited/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </IconLink>
      </IconContainer>
    </PageContainer>
  );
};

export default DiscoverUsOnline;
