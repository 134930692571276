import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from './img/logo.png'; // Import company logo directly

// Background animation
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

// Define colors directly in the component
const colors = {
  backgroundColor1: '#141414',
  backgroundColor2: '#1e1e2a',
  backgroundColor3: '#302b63',
  buttonColor: '#ffd700',
  textColor: 'white',
};

// Styled components
const CompletionPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, ${colors.backgroundColor1}, ${colors.backgroundColor2}, ${colors.backgroundColor3}, ${colors.buttonColor});
  background-size: 300% 300%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  color: ${colors.textColor};
  text-align: center;
  padding: 20px;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 20px;
`;

const VideoFrame = styled.iframe`
  width: 100%;
  max-width: 640px; /* Limit the max width for larger screens */
  height: 360px;
  border: none;
  margin-top: 20px;
`;

const Button = styled.button`
  width: 50%; /* Make button full width */
  max-width: 300px; /* Optional: Set a maximum width for larger screens */
  margin-top: 60px; /* Add some space above the button */
  padding: 10px;
  background-color: ${colors.buttonColor};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #141414;
  font-size: 16px;
  text-align: center; /* Center button text */
`;

const LoadingMessage = styled.p`
  font-size: 20px;
  color: ${colors.textColor};
`;

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the video and button */
  width: 100%;
`;

const CompletionPage = () => {
  const { state } = useLocation();
  const { username, courseName } = state || {};
  const [videoId, setVideoId] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch the completion video ID from the backend
  useEffect(() => {
    const fetchCompletionVideo = async () => {
      try {
        const response = await axios.post('https://courseapp1.onrender.com/api/getCompletionVideo');
        const fetchedVideoId = response.data.videoId;

        if (fetchedVideoId) {
          setVideoId(fetchedVideoId);
        } else {
          console.error('No video ID found in the completion sheet.');
        }
      } catch (error) {
        console.error('Error fetching completion video:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletionVideo();
  }, []);

  const handleEnterFamily = () => {
    // Navigate to the FamilyFormPage
    navigate('/family-form', { state: { username, courseName } });
  };

  return (
    <CompletionPageWrapper>
      <Logo src={logo} alt="Company Logo" />
      <h1>Congratulations</h1>
      {loading ? (
        <LoadingMessage>Loading...</LoadingMessage>
      ) : videoId ? (
        <VideoContainer>
          <VideoFrame
            src={`https://player.vimeo.com/video/${videoId}`}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Completion Video"
          />
          <Button onClick={handleEnterFamily}>
            Enter the Family
          </Button>
        </VideoContainer>
      ) : (
        <p>No video available.</p>
      )}
    </CompletionPageWrapper>
  );
};

export default CompletionPage;
