import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import logo from './img/logo.png'; // Import the company logo

// Keyframes for background animation
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

// Wrapper for SignUp Page
const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  font-family: 'Poppins', sans-serif;
  color: #E0E0E0;
  padding: 20px;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const Title = styled.h1`
  color: #ffd700; // White color for title
  margin-bottom: 20px;
  text-align: center; // Center align the title
`;

const InputField = styled.input`
  width: 100%;
  max-width: 400px; // Maximum width for input fields
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ffd700; // Gold border
  border-radius: 5px;
  font-size: 1em;
  color: #141414; // Dark text color
`;

const SignUpButton = styled.button`
  padding: 10px 20px;
  background-color: #ffd700; // Button color
  border: none;
  color: #141414; // Button text color
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  margin: 10px 0; // Space between buttons
`;

// Modal for Confirmation Message
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); // Dark overlay for better visibility
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure it appears on top of other content
`;

const ModalContent = styled.div`
  background: #1e1e2a; // Match the background color
  color: #ffd700; // Gold text color
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px; // Set a fixed width for modal
`;

const ModalButton = styled.button`
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #ffd700; // Button color
  border: none;
  color: #141414; // Button text color
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0b800; // Darken the button on hover
  }
`;

// Social Media Icons Container
const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
`;

// Social Media Icon Styling
const SocialIcon = styled(FontAwesomeIcon)`
  color: #ffd700;
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

// Main SignUp Component
const SignUpPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const handleSignUp = async (e) => {
    e.preventDefault();

    // Prepare data to send
    const signUpData = {
      firstName,
      lastName,
      phoneNumber,
      email,
    };

    try {
      // Call the sign-up API
      const response = await fetch('https://courseapp1.onrender.com/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(signUpData),
      });

      // Handle the response
      if (response.ok) {
        // Show confirmation modal
        setModalVisible(true);

        // Optionally reset the form
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setEmail('');
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.error); // Display error message
      }
    } catch (error) {
      console.error('Error:', error); // Handle network errors
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    navigate('/'); // Navigate to indexpage.js after closing the modal
  };

  const handleSocialMediaClick = (url) => {
    window.open(url, '_blank'); // Open link in new tab
  };

  return (
    <SignUpWrapper>
      <Logo src={logo} alt="Company Logo" />
      <Title>Sign Up</Title>
      <form onSubmit={handleSignUp} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <InputField
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <InputField
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <InputField
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
        <InputField
          type="email"
          placeholder="Email ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <SignUpButton type="submit">Sign Up</SignUpButton>
      </form>
      {modalVisible && (
        <ModalOverlay>
          <ModalContent>
            <h3>Thank you for signing up with Trackpi.</h3>
            <p>Please check your email in 5 minutes for your username and password.</p>
            <ModalButton onClick={handleModalClose}>Ok</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
      <SocialMediaContainer>
        <SocialIcon icon={faFacebook} onClick={() => handleSocialMediaClick('https://www.facebook.com/profile.php?id=61565947096778')} />
        <SocialIcon icon={faInstagram} onClick={() => handleSocialMediaClick('https://www.instagram.com/trackpi_official?igsh=YmwyaHpzYXBueWJz')} />
        <SocialIcon icon={faYoutube} onClick={() => handleSocialMediaClick('https://www.youtube.com/@Trackpi-p3s')} />
        <SocialIcon icon={faLinkedin} onClick={() => handleSocialMediaClick('https://www.linkedin.com/company/trackpi-private-limited/?viewAsMember=true')} />
      </SocialMediaContainer>
    </SignUpWrapper>
  );
};

export default SignUpPage;
