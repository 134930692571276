import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import logo from './img/logo.png'; // Import the company logo

// Keyframes for background animation
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

// Keyframes for loading spinner
const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled-components for mobile-friendly and responsive design
const AssessmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 300% 300%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  font-family: 'Poppins', sans-serif;
  color: #E0E0E0;
  padding: 20px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; // Center align buttons
    margin-top: 20px; // Add some space above
`;

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* Fixed position to cover the whole page */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 10); /* Semi-transparent background */
  color: #ffd700; /* Text color */
  font-size: 13px; /* Font size for loading text */
  z-index: 9999; /* Ensure it appears above other elements */
`;

const Spinner = styled.div`
  margin-top: 20px;
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
  border: 5px solid #ffd700;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const Title = styled.h1`
  color: #ffffff; // White color for title
  margin-bottom: 20px;
  text-align: center; // Center align the title
`;

const Timer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px; /* Size of the timer text */
  color: #ffd700; /* Timer color */
`;

const Button = styled.button`
    padding: 10px 20px;
    background-color: #ffd700; // Match the button color to the theme
    border: none;
    color: #141414; // Text color for buttons
    font-size: 1em;
    font-family: 'Poppins', sans-serif; // Change the font family to Poppins
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.3s;
    &:hover {
        transform: scale(1.05);
    }
    margin: 10px 0; // Space between buttons
`;

const QuestionContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1); /* Light background for contrast */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding inside the container */
  margin: 10px 0; /* Margin between questions */
  width: 100%; /* Full width to fit the parent */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  text-align: left; /* Left align the question text */
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center; /* Align options vertically */
  margin: 5px 0; /* Space between options */
  text-align: left; /* Ensure options are left aligned */
  padding-left: 20px; /* Add some padding to align with question text */
`;

const AssessmentPage = () => {
    const { state } = useLocation();
    const { assessmentId, assessmentName, username, courseName } = state || {}; // Destructure username and courseName
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [answers, setAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [score, setScore] = useState(0);
    const [isAssessmentStarted, setIsAssessmentStarted] = useState(false); // State to track assessment start
    const [timeRemaining, setTimeRemaining] = useState(900); // 15 minutes in seconds
    const navigate = useNavigate();

    // Calculate total marks and pass mark (3 wrong questions allowed)
    const totalMarks = questions.length * 2;
    const passMark = totalMarks - 6;

    const fetchQuestions = useCallback(async () => {
        try {
            const response = await axios.post('https://courseapp1.onrender.com/api/getAssessmentQuestions', {
                assessmentId,
            });
            setQuestions(response.data.questions);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching assessment questions:", error);
            setLoading(false);
        }
    }, [assessmentId]);

    // Fetch questions when component mounts
    useEffect(() => {
        fetchQuestions();
    }, [fetchQuestions]);

    useEffect(() => {
        let timer;
        if (isAssessmentStarted && timeRemaining > 0) {
            timer = setInterval(() => {
                setTimeRemaining(prev => prev - 1);
            }, 1000);
        } else if (timeRemaining === 0) {
            handleSubmit(); // Automatically submit when time is up
        }
        return () => clearInterval(timer);
    }, [isAssessmentStarted, timeRemaining]);

    const handleAnswerChange = (questionIndex, selectedOption) => {
        setAnswers(prev => ({ ...prev, [questionIndex]: selectedOption }));
    };

    const handleSubmit = () => {
        let totalScore = 0;

        // Calculate total score based on correct answers
        questions.forEach((question, index) => {
            if (answers[index] === question.correctAnswer) {
                totalScore += 2; // Assuming each question carries 2 marks
            }
        });

        setScore(totalScore);

        if (totalScore >= passMark) {
            setSubmitted(true); // User passes the assessment
        } else {
            setSubmitted(false); // User fails the assessment
        }
    };

    const handleRetake = () => {
        setSubmitted(false);
        setAnswers({});
        setScore(0);
        setIsAssessmentStarted(false); // Reset assessment start state
        setTimeRemaining(900); // Reset time remaining to 15 minutes
    };

    const handleStartAssessment = () => {
        setIsAssessmentStarted(true); // Start the assessment
    };

    const handleNextUnit = async () => {
        try {
            console.log("Username:", username);
            console.log("Course Name:", courseName);

            const response = await axios.post('https://courseapp1.onrender.com/api/updateProgress', {
                username, 
                courseName,
            });

            if (response.data.success) {
                const progressResponse = await axios.post('https://courseapp1.onrender.com/api/getProgress', { username });
                const userProgress = progressResponse.data.find(item => item.courseName === courseName);
                const nextVideoIndex = userProgress ? parseInt(userProgress.videoIndex, 10) : 0;

                const videoDetailsResponse = await axios.post('https://courseapp1.onrender.com/api/getVideoDetails', { courseName });
                const videoDetails = videoDetailsResponse.data;
                const nextVideo = videoDetails[nextVideoIndex];

                if (nextVideo) {
                    const { videoId, videoTitle, assessmentId, assessmentName } = nextVideo;
                    navigate(`/video-player/${videoId}`, {
                        state: {
                            username,
                            courseName,
                            videoId,
                            videoTitle,
                            assessmentId,
                            assessmentName,
                            startIndex: nextVideoIndex
                        }
                    });
                } else {
                    const markCompletedResponse = await axios.post('https://courseapp1.onrender.com/api/markCourseAsCompleted', {
                        username,
                        courseName,
                    });

                    if (markCompletedResponse.data.success) {
                        navigate('/completion');
                    } else {
                        console.error("Failed to mark course as completed:", markCompletedResponse.data.error);
                    }
                }
            } else {
                console.error('Failed to update progress:', response.data.error);
            }
        } catch (error) {
            console.error('Error updating progress:', error);
        }
    };

    // Check if all questions have been answered
    const allQuestionsAnswered = questions.length > 0 && questions.length === Object.keys(answers).length;

    return (
        <AssessmentContainer>
            <Logo src={logo} alt="Company Logo" />
            <Title>{assessmentName}</Title>
            {isAssessmentStarted && (
                <Timer>
                    Time Remaining: {Math.floor(timeRemaining / 60)}:{(timeRemaining % 60).toString().padStart(2, '0')}
                </Timer>
            )}
            {loading ? (
                <p>Loading...</p>
            ) : (
                <>
                    {!isAssessmentStarted ? (
                        <Button onClick={handleStartAssessment}>Start Assessment</Button>
                    ) : submitted ? (
                        <div>
                            <p>Congratulations! You scored {score} out of {questions.length * 2}.</p>
                            <ButtonContainer>
                                {score < passMark && (
                                    <Button onClick={handleRetake}>Retake Assessment</Button>
                                )}
                                {score >= passMark && (
                                    <Button onClick={handleNextUnit}>Move to Next Unit</Button>
                                )}
                            </ButtonContainer>
                        </div>
                    ) : (
                        <div>
                            {questions.map((question, index) => (
                                <QuestionContainer key={index}>
                                    <p>{question.question}</p>
                                    {question.options.map((option, idx) => (
                                        <OptionContainer key={idx}>
                                            <input
                                                type="radio"
                                                id={`q${index}o${idx}`}
                                                name={`q${index}`}
                                                value={option}
                                                checked={answers[index] === option}
                                                onChange={() => handleAnswerChange(index, option)}
                                            />
                                            <label htmlFor={`q${index}o${idx}`}>{option}</label>
                                        </OptionContainer>
                                    ))}
                                </QuestionContainer>
                            ))}
                            <Button 
                                onClick={handleSubmit} 
                                disabled={!allQuestionsAnswered} // Submit button enabled only when all questions are answered
                            >
                                Submit Assessment
                            </Button>
                        </div>
                    )}
                </>
            )}
        </AssessmentContainer>
    );
};

export default AssessmentPage;