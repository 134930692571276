import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import styled, { keyframes } from 'styled-components';
import logo from './img/logo.png';

// Keyframes for background animation
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

// Wrapper for Need Help Page
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 20s ease infinite;
  font-family: 'Poppins', sans-serif;
  color: #E0E0E0;
  padding: 20px;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  margin-top: 20px;
`;

const Title = styled.h1`
  color: #ffd700;
  margin: 20px 0;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1.2em;
  color: #E0E0E0;
  text-align: center;
  margin: 10px 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
`;

const InputField = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ffd700;
  border-radius: 5px;
  font-size: 1em;
  color: #000;
`;

const TextAreaField = styled.textarea`
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ffd700;
  border-radius: 5px;
  font-size: 1em;
  color: #000;
  resize: none;
  height: 100px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #ffd700;
  border: none;
  color: #141414;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

const ContactInfo = styled.div`
  font-size: 1em;
  color: #E0E0E0;
  text-align: center;
  margin: 20px 0;
`;

// Modal for Confirmation Message
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #1e1e2a;
  color: #ffd700;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 300px;
`;

const ModalButton = styled.button`
  margin-top: 15px;
  padding: 10px 15px;
  background-color: #ffd700;
  border: none;
  color: #141414;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0b800;
  }
`;

const NeedHelpPage = () => {
  const [fullName, setFullName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [issueDescription, setIssueDescription] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleSubmit = async (e) => {
    e.preventDefault();

    const helpRequestData = {
      fullName,
      contactNumber,
      issueDescription,
    };

    try {
      const response = await fetch('https://courseapp1.onrender.com/api/needhelp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(helpRequestData),
      });

      if (response.ok) {
        setModalVisible(true);
        setFullName('');
        setContactNumber('');
        setIssueDescription('');
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    navigate('/userdashboard'); // Use navigate to redirect after modal close
  };

  return (
    <PageContainer>
      <Logo src={logo} alt="Company Logo" />
      <Title>Need Help?</Title>
      <Description>
        We're here to assist you! If you have any questions or need support, please reach out to us or submit the details.
      </Description>

      <ContactInfo>
        <p>Email: operations@tracpi.in</p>
        <p>Phone: (+91) 80781 79646</p>
        <p>Chat with us 24/7</p>
      </ContactInfo>

      <form onSubmit={handleSubmit}>
        <InputContainer>
          <InputField
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
          <InputField
            type="tel"
            placeholder="Contact Number"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            required
          />
          <TextAreaField
            placeholder="Explain your issue (English or Malayalam)"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
            required
          />
          <SubmitButton type="submit">Submit</SubmitButton>
        </InputContainer>
      </form>

      {modalVisible && (
        <ModalOverlay>
          <ModalContent>
            <h3>Thank you for your help request.</h3>
            <p>We will get back to you shortly!</p>
            <ModalButton onClick={handleModalClose}>Ok</ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </PageContainer>
  );
};

export default NeedHelpPage;
