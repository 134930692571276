import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserDashboard from './UserDashboard';
import IndexPage from './IndexPage';
import SignUpPage from './SignUpPage';
import DiscoverUsOnline from './DiscoverUsOnline';
import ContactUs from './ContactUs';
import FAQ from './FAQ';
import AboutTrackpi from './AboutTrackpi';
import MyCourses from './MyCourses';
import VideoPlayer from './VideoPlayer'; // Adjust path as necessary
import AssessmentPage from './AssessmentPage'; // Adjust path as necessary
import CompletionPage from './CompletionPage';
import FamilyFormPage from './FamilyFormPage'; // Import the Family Form Page
import NeedHelpPage from './NeedHelpPage'; // Import the Need Help Page

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<IndexPage />} /> {/* Home Page */}
                <Route path="/userdashboard" element={<UserDashboard />} /> {/* User Dashboard */}
                <Route path="/my-course" element={<MyCourses />} /> {/* My Courses Page */}
                <Route path="/signup" element={<SignUpPage />} /> {/* Sign Up Page */}
                <Route path="/discover-us-online" element={<DiscoverUsOnline />} /> {/* Discover Us Page */}
                <Route path="/contact-us" element={<ContactUs />} /> {/* Contact Us Page */}
                <Route path="/faq" element={<FAQ />} /> {/* Frequently Asked Questions */}
                <Route path="/video-player/:videoId" element={<VideoPlayer />} />
                <Route path="/assessment" element={<AssessmentPage />} />
                <Route path="/completion" element={<CompletionPage />} />
                <Route path="/family-form" element={<FamilyFormPage />} /> {/* Add the Family Form Page */}
                <Route path="/about-trackpi" element={<AboutTrackpi />} /> {/* About Trackpi Page */}
                <Route path="/needhelppage" element={<NeedHelpPage />} /> {/* Add the Need Help Page */}
            </Routes>
        </Router>
    );
}

export default App;
