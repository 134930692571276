// AboutTrackpi.js
import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  color: #E0E0E0;
`;

const Title = styled.h1`
  font-size: 2em;
  color: #ffffff;
  margin-bottom: 20px;
`;

const PDFLink = styled.a`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffd700; /* Gold background */
  color: #141414; /* Dark text */
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2em;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0b800; /* Darker gold on hover */
  }
`;

const AboutTrackpi = () => {
  return (
    <PageContainer>
      <Title>About Trackpi</Title>
      <PDFLink 
        href="https://drive.google.com/uc?id=14BxvvQhy_FrUDoBaUZ3t7hXijNbJWRVg" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Open Company PDF
      </PDFLink>
    </PageContainer>
  );
};

export default AboutTrackpi;
