import React, { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import logo from './img/logo.png';
import axios from 'axios';

// Background Gradient Animation
const BackgroundAnimation = keyframes`
  0% { background-position: 100% 50%; }
  50% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
`;

// Loading Animation
const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Logo Rotation Animation
const LogoRotation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Logo Fade-In Animation
const LogoFadeIn = keyframes`
  0% { opacity: 0; transform: scale(0.5); }
  100% { opacity: 1; transform: scale(1); }
`;

// Text Fade-In Animation
const TextFadeIn = keyframes`
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
`;

// Text Underline Animation
const UnderlineAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

// Wrapper with Dynamic Background
const IndexPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #141414, #1e1e2a, #302b63, #ffd700);
  background-size: 400% 400%;
  animation: ${BackgroundAnimation} 30s ease infinite;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
`;

// Loading Screen
const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  color: #ffd700;
  font-size: 13px;
`;

const Spinner = styled.div`
  margin-top: 20px;
  width: 20px;
  height: 20px;
  border: 5px solid #ffd700;
  border-top: 5px solid transparent;
  border-radius: 50%;
  animation: ${spinAnimation} 1s linear infinite;
`;

// Logo Styling with Animation
const Logo = styled.img`
  width: 550px;
  height: auto;
  animation: ${LogoRotation} 2s linear, ${LogoFadeIn} 2s ease forwards;
`;

// Clickable Text with Gradient, Shadow, and Animation
const ClickableText = styled.span`
  font-size: 16px;
  font-weight: bold;
  background: linear-gradient(45deg, #ffd700, #ffffff);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  opacity: 0;
  animation: ${TextFadeIn} 3s ease forwards 1.5s;
  position: relative;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  &:hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: #ffd700;
    animation: ${UnderlineAnimation} 0.3s ease forwards;
  }
`;

// Button Styling with Transient Prop
const Button = styled.button`
  background: linear-gradient(135deg, #ffd700, #f1c40f);
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 70px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  opacity: 0;
  transform: translateY(10px);
  ${({ $show }) =>
    $show &&
    css`
      animation: ${TextFadeIn} 1s ease forwards;
    `}
  &:hover {
    background: linear-gradient(135deg, #f1c40f, #ffd700);
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  &:focus {
    outline: none;
  }
`;

// Login Form Styling
const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  margin-top: 20px;
  animation: ${TextFadeIn} 1s ease forwards;
`;

// Input Styling
const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ffd700;
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  width: 100%;
  text-align: left;
  &:focus {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(255, 215, 0, 0.5);
  }
`;

// Eye Icon Styling
const EyeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: -10px;
  top: 40%;
  transform: translateY(-50%);
  color: #ffd700;
  cursor: pointer;
`;

// Social Media Icons Container
const SocialMediaContainer = styled.div`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  animation: ${TextFadeIn} 1.5s ease forwards;
`;

const SocialIcon = styled(FontAwesomeIcon)`
  color: #ffd700;
  font-size: 30px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

// Alert Styling
const Alert = styled.div`
  background-color: rgba(255, 0, 0, 0.8); // Red background
  color: white;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  transition: opacity 0.5s ease;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const IndexPage = () => {
  const navigate = useNavigate();
  const [showText, setShowText] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility
  const [alertVisible, setAlertVisible] = useState(false); // Manage alert visibility
  const [alertMessage, setAlertMessage] = useState(''); // Alert message

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowText(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleTextClick = () => {
    setShowText(false);
    setShowButtons(true);
  };

  const handleLoginClick = () => {
    setShowLoginForm(true);
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loading screen

    try {
      const response = await axios.post('https://courseapp1.onrender.com/api/verify-credentials', {
        username,
        password
      });

      if (response.data.success) {
        // Store only necessary data in sessionStorage
        sessionStorage.setItem('username', response.data.username);
        sessionStorage.setItem('fullName', response.data.fullName);
        sessionStorage.setItem('userCourses', JSON.stringify(response.data.courses || [])); // Ensure 'courses' is handled properly
        sessionStorage.setItem('authToken', response.data.token);

        // Redirect to UserDashboard
        navigate('/userdashboard');
      } else {
        setAlertMessage('Invalid credentials. Please try again.');
        setAlertVisible(true);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setAlertMessage('Oops! Those credentials don’t match. Please re-enter them.');
      setAlertVisible(true);
    } finally {
      setLoading(false); // Hide loading screen
    }
  };

  const handleSocialMediaClick = (url) => {
    window.open(url, '_blank'); // Open link in new tab
  };

  if (loading) {
    return (
      <LoadingScreen>
        Verifying your account...
        <Spinner />
      </LoadingScreen>
    );
  }

  return (
    <IndexPageWrapper>
      <Logo src={logo} alt="Company Logo" />
      {showLoginForm ? (
        <LoginForm onSubmit={handleLoginSubmit}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#ffd700' }}>Login</h2>
          <InputContainer>
            <Input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </InputContainer>
          <InputContainer>
            <Input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <EyeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={() => setShowPassword(!showPassword)}
            />
          </InputContainer>
          {alertVisible && <Alert visible={alertVisible}>{alertMessage}</Alert>}
          <Button type="submit" $show={true}>Verify</Button>
        </LoginForm>
      ) : (
        <>
          {showText && (
            <ClickableText onClick={handleTextClick}>
              Welcome aboard freelancer, <span style={{ textDecoration: 'underline' }}>Click me</span>
            </ClickableText>
          )}
          {showButtons && (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px', marginTop: '20px' }}>
              <Button $show={showButtons} onClick={handleSignUpClick}>Sign up</Button>
              <Button $show={showButtons} onClick={handleLoginClick}>Login</Button>
            </div>
          )}
        </>
      )}
      <SocialMediaContainer $show={showButtons}>
        <SocialIcon icon={faFacebook} onClick={() => handleSocialMediaClick('https://www.facebook.com/profile.php?id=61565947096778')} />
        <SocialIcon icon={faInstagram} onClick={() => handleSocialMediaClick('https://www.instagram.com/trackpi_official?igsh=YmwyaHpzYXBueWJz')} />
        <SocialIcon icon={faYoutube} onClick={() => handleSocialMediaClick('https://www.youtube.com/@Trackpi-p3s')} />
        <SocialIcon icon={faLinkedin} onClick={() => handleSocialMediaClick('https://www.linkedin.com/company/trackpi-private-limited/?viewAsMember=true')} />
      </SocialMediaContainer>
    </IndexPageWrapper>
  );
};

export default IndexPage;
